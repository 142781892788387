import { DEFAULT_COLOR_SETTINGS } from '../config/constants';
import { DEFAULT_COLUMNS } from '../config/columnConfig';
import { MARKET_CAP_TIERS } from '../components/MarketCapFilter';

export const loadSettings = () => {
  const settings = localStorage.getItem('userSettings');
  const defaultSettings = {
    colorSettings: DEFAULT_COLOR_SETTINGS,
    columns: DEFAULT_COLUMNS.map(col => col.key),
    marketCapFilters: MARKET_CAP_TIERS.map(tier => tier.id),
    newsCarousel: {
      speed: 8000,
      isPaused: false
    },
    economicEvents: {
      selectedTimeframes: [0],
      geography: 'US',
      showEvents: true
    }
  };

  if (!settings) {
    return defaultSettings;
  }

  try {
    const parsedSettings = JSON.parse(settings);
    return {
      ...defaultSettings,
      ...parsedSettings
    };
  } catch (error) {
    console.error('Error parsing settings:', error);
    return defaultSettings;
  }
};

export const saveSettings = (category, value) => {
  const currentSettings = loadSettings();
  const updatedSettings = {
    ...currentSettings,
    [category]: value
  };
  localStorage.setItem('userSettings', JSON.stringify(updatedSettings));
  return updatedSettings;
};
