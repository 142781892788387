import React, { useCallback } from 'react';
import './EconomicEventControls.css';

const EconomicEventControls = ({ 
  userSettings = {},
  onSettingsChange,
  showEconomicEvents,
  setShowEconomicEvents
}) => {
  // Log initial props
  console.log('EconomicEventControls props:', {
    userSettings,
    showEconomicEvents
  });

  // Destructure settings with complete defaults
  const { 
    economicEvents = {
      selectedTimeframes: [0],
      geography: 'US',
      showEvents: true
    }
  } = userSettings;

  const handleTimeframeChange = useCallback((value) => {
    console.log('handleTimeframeChange called with:', value);
    
    // Get current timeframes with proper default
    const currentTimeframes = economicEvents?.selectedTimeframes || [0];
    console.log('Current timeframes:', currentTimeframes);
    
    // Toggle the selected timeframe - allow complete deselection
    const newTimeframes = currentTimeframes.includes(value)
      ? currentTimeframes.filter(t => t !== value)
      : [...currentTimeframes, value];

    console.log('New timeframes:', newTimeframes);

    // Update settings
    onSettingsChange('economicEvents', {
      ...economicEvents,
      selectedTimeframes: newTimeframes,
      showEvents: newTimeframes.length > 0 // Only show events if timeframes are selected
    });

    // Update visibility based on whether any timeframes are selected
    setShowEconomicEvents(newTimeframes.length > 0);
  }, [economicEvents, onSettingsChange, setShowEconomicEvents]);

  const handleGeographyChange = useCallback((e) => {
    console.log('handleGeographyChange called with:', e.target.value);
    onSettingsChange('economicEvents', {
      ...economicEvents,
      geography: e.target.value
    });
  }, [economicEvents, onSettingsChange]);

  // Log current state
  console.log('Current economicEvents state:', economicEvents);

  return (
    <div className="bg-gray-100 py-2 px-4 text-xs flex items-center justify-between border-t border-gray-200">
      <div className="flex items-center space-x-4">
        <span className="font-medium text-sm z-0 relative">Economic Events:</span>
        <div className="flex items-center space-x-2">
          {[-1, 0, 1].map((value) => {
            const isSelected = economicEvents?.selectedTimeframes?.includes(value);
            
            return (
              <button
                key={value}
                onClick={() => handleTimeframeChange(value)}
                className={`px-2 py-1 rounded text-xs ${
                  isSelected
                    ? 'bg-blue-500 text-white' 
                    : 'bg-white text-gray-700 border border-gray-300'
                } hover:bg-blue-100`}
              >
                {value === -1 ? 'Last Week' : value === 0 ? 'This Week' : 'Next Week'}
              </button>
            );
          })}
        </div>
      </div>
      <div className="flex items-center">
        <span className="mr-2 font-medium text-sm">Geography:</span>
        <select
          value={economicEvents?.geography || 'US'}
          onChange={handleGeographyChange}
          className="p-1 border rounded bg-white text-xs"
        >
          {['US', 'EU', 'UK', 'JP', 'CN'].map((country) => (
            <option key={country} value={country}>{country}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default React.memo(EconomicEventControls);