import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode'; // Corrected the import
import './Header.css';
import GoogleLoginButton from './GoogleLoginButton';
import Modal from 'react-modal';
import { Menu, Transition } from '@headlessui/react';
import CONFIG from '../config/config';
import StatusHeader from './StatusHeader';

Modal.setAppElement('#root');

const Header = ({ onLoginSuccess, onLogout, trialDaysLeft, isSubscribed, showReauth, appStatus, onProfileClick }) => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showDeleteSubmenu, setShowDeleteSubmenu] = useState(false);

  const toggleDropdown = () => {
    console.log('Toggling dropdown');
    setIsDropdownOpen(prev => {
      console.log('New dropdown state:', !prev);
      return !prev;
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && !isTokenExpired(token)) {
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.email) {
        setLoggedInUser(decodedToken.email);
        onLoginSuccess(decodedToken.email);
      }
    } else {
      // Token is expired or doesn't exist
      setLoggedInUser(null);
      setShowLoginButton(true);
      localStorage.removeItem('authToken'); // Remove expired token
    }
  }, [onLoginSuccess]);

  useEffect(() => {
    if (showReauth) {
      setLoggedInUser(null);
      setShowLoginButton(true);
    }
  }, [showReauth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLoginSuccess = (response) => {
    if (response && response.credential) {
      const decodedToken = jwtDecode(response.credential);
      if (decodedToken && decodedToken.email && !isTokenExpired(response.credential)) {
        setLoggedInUser(decodedToken.email);
        onLoginSuccess(decodedToken.email);
        localStorage.setItem('authToken', response.credential);
        setShowLoginButton(false);
      } else {
        console.error('Received expired or invalid token');
        setShowLoginButton(true);
      }
    }
  };

  const handleLogout = () => {
    setLoggedInUser(null);
    setShowLoginButton(true);
    setIsDropdownOpen(false);
    localStorage.removeItem('authToken');
    onLogout(); // Call the onLogout prop
  };

  const getUserInitials = (email) => {
    return email
      .split('@')[0]
      .split('.')
      .map(name => name[0].toUpperCase())
      .join('');
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

  const handleLoginFailure = (error) => {
    console.error('Login Failure:', error);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubscribe = () => {
    // Add subscription logic
    closeModal();
  };

  const handleSubscriptionClick = () => {
    window.open('https://billing.stripe.com/p/login/00g9Er4TpfdqdHOcMM', '_blank');
  };

  const handleDeleteProfile = async () => {
    if (window.confirm("Are you sure you want to delete your profile? This action cannot be undone.")) {
      try {
        const response = await fetch(`${CONFIG.API_URL}/api/user-data?userId=${encodeURIComponent(loggedInUser)}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.ok) {
          handleLogout();
          alert("Your profile has been deleted successfully.");
        } else {
          throw new Error('Failed to delete profile');
        }
      } catch (error) {
        console.error('Error deleting profile:', error);
        alert("An error occurred while deleting your profile. Please try again later.");
      }
    }
  };

  return (
    <>
      {appStatus && <StatusHeader message={appStatus.message} type={appStatus.type} />}
      <div className="header">
        <div className="logo">
          <img src="./quantape.png" alt="First Principles Analytics Logo" className="logo-image" />
          <div className="title-container">
            <h1>QuantApe Smart Screener</h1>
            <h2>For the Investor Who Refuses to Be Ordinary</h2>
          </div>
        </div>
        <div className="right-section">
          {!isSubscribed && trialDaysLeft > 0 && (
            <div className="trial-banner" onClick={openModal}>
              <span>{trialDaysLeft} days left on Free Trial</span>
            </div>
          )}
          {loggedInUser ? (
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="user-icon-button">
                <div className="user-icon">
                  <span className="user-initials">{getUserInitials(loggedInUser)}</span>
                </div>
              </Menu.Button>
              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={onProfileClick}
                          className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FontAwesomeIcon icon={faUser} className="mr-2" />
                          Profile
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          className={`${
                            active ? 'bg-blue-500 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <GoogleLoginButton
              onLoginSuccess={handleLoginSuccess}
              onLoginFailure={handleLoginFailure}
            />
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Subscription Plans"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <button onClick={closeModal} className="close-modal-button">X</button>
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          <stripe-pricing-table
            pricing-table-id="prctbl_1PRjBcK2h01IjPAi0ihULu1L"
            publishable-key="pk_live_01aAutTYAQHsauVPglKbqeln00sTJBIfF2"
            onSubscriptionCreated={handleSubscribe}
          >
          </stripe-pricing-table>
        </Modal>
      </div>
    </>
  );
};

export default Header;
