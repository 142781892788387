import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronDown, FiChevronUp, FiTrendingUp, FiClock, FiAlertTriangle } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import CONFIG from '../config/config';
import NewsRow from './NewsRow';
import { formatPercentage } from './utils';
import Tooltip from './Tooltip';

const tierLabels = {
  mega: <span className="market-cap-label">Mega Cap <span className="market-cap-range">(&gt;$200B)</span></span>,
  large: <span className="market-cap-label">Large Cap <span className="market-cap-range">($10B-$200B)</span></span>,
  mid: <span className="market-cap-label">Mid Cap <span className="market-cap-range">($2B-$10B)</span></span>,
  small: <span className="market-cap-label">Small Cap <span className="market-cap-range">($300M-$2B)</span></span>,
  micro: <span className="market-cap-label">Micro Cap <span className="market-cap-range">(&lt;$300M)</span></span>
};

const fetchMarketAnalysis = async (forceRefresh = false) => {
  const today = new Date().toISOString().split('T')[0];
  try {
    const response = await axios.get(
      `https://market_news_2.drisw.workers.dev/`
    );
    console.log('Raw API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching market analysis:', error);
    throw error;
  }
};

// Add new error boundary component
const ErrorBoundarySection = ({ children, error, isLoading }) => {
  if (isLoading) return <div className="p-3 text-gray-500">Loading...</div>;
  if (error) return (
    <div className="p-3 text-red-500 text-sm">
      <FiAlertTriangle className="inline mr-2" />
      Unable to load data: {error.message || 'Unknown error'}
    </div>
  );
  return children;
};

// Separate Market Pulse section
const MarketPulseSection = ({ data, isLoading, error }) => {
  if (!data && !error && !isLoading) return null;
  
  return (
    <ErrorBoundarySection error={error} isLoading={isLoading}>
      {data && getMarketPulseAnalysis(data)}
    </ErrorBoundarySection>
  );
};

// Separate Market Analysis section
const MarketAnalysisSection = ({ data, isLoading, error }) => {
  console.log('MarketAnalysisSection data:', data);
  console.log('MarketAnalysisSection Summary:', data?.analysis?.Summary);
  
  if (!data && !error && !isLoading) return null;

  return (
    <ErrorBoundarySection error={error} isLoading={isLoading}>
      {data?.analysis && (
        <>
          <div className="py-2.5 text-gray-600">
            {data.analysis.Summary?.marketOutlook || 'No market outlook available'}
          </div>
          
          {/* Add Sector Outlook */}
          {data.analysis.Summary?.sectorOutlook && (
            <div className="mt-3 sector-outlook-container">
              <h4 className="text-sm font-semibold">Sector Outlook:</h4>
              <div className="sector-outlook">
                {Object.entries(data.analysis.Summary.sectorOutlook).map(([sector, sectorData]) => (
                  <Tooltip 
                    key={sector} 
                    text={
                      <div className="max-w-xs">
                        <div className="font-semibold mb-1">{sectorData.analysis}</div>
                        <div className="mt-2">
                          <span className="font-medium">Key Drivers:</span>
                          <ul className="list-disc pl-4 mt-1">
                            {sectorData.keyDrivers.map((driver, idx) => (
                              <li key={idx}>{driver}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    }
                  >
                    <div className={`sector-badge ${sectorData.sentiment}`}>
                      <span className="font-medium capitalize">{sector}</span>
                      <span className="mx-1">•</span>
                      <span className="capitalize">{sectorData.sentiment}</span>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </ErrorBoundarySection>
  );
};

// Move getMarketPulseAnalysis outside of MarketAnalysisRow component
const getMarketPulseAnalysis = (rawData) => {
  if (!rawData?.data) return null;
  
  const {
    numPriceIncreases,
    numPriceDecreases,
    topIndustriesIncreases,
    topIndustriesDecreases,
    marketCapTiers
  } = rawData.data;
  
  return (
    <div className="space-y-4">
      {/* Market Cap Tiers Table */}
      {marketCapTiers && (
        <div className="overflow-x-auto">
          <table className="min-w-full text-sm">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-3 py-2 text-left font-medium text-gray-700">Market Cap</th>
                <th className="px-3 py-2 text-right font-medium text-green-700">Up %</th>
                <th className="px-3 py-2 text-right font-medium text-red-700">Down %</th>
                <th className="px-3 py-2 text-right font-medium text-green-700">Avg Up</th>
                <th className="px-3 py-2 text-right font-medium text-red-700">Avg Down</th>
                <th className="px-3 py-2 text-right font-medium text-blue-700">Near High</th>
                <th className="px-3 py-2 text-right font-medium text-orange-700">Near Low</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {marketCapTiers.map((tier) => (
                <tr key={tier.market_cap_tier} className="hover:bg-gray-50">
                  <td className="px-3 py-2">{tierLabels[tier.market_cap_tier]}</td>
                  <td className="px-3 py-2 text-right">
                    <span className={tier.increasing_percentage >= 50 ? 'text-green-600' : 'text-gray-600'}>
                      {formatPercentage(tier.increasing_percentage)}
                    </span>
                  </td>
                  <td className="px-3 py-2 text-right">
                    <span className="text-red-600">
                      {formatPercentage(100 - tier.increasing_percentage)}
                    </span>
                  </td>
                  <td className="px-3 py-2 text-right text-green-600">
                    {formatPercentage(tier.avg_increase)}
                  </td>
                  <td className="px-3 py-2 text-right text-red-600">
                    {formatPercentage(tier.avg_decrease)}
                  </td>
                  <td className="px-3 py-2 text-right text-blue-600">
                    {formatPercentage(tier.near_high_percentage)}
                  </td>
                  <td className="px-3 py-2 text-right text-orange-600">
                    {formatPercentage(tier.near_low_percentage)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      <div className="market-sectors">
        <div className="sector-group">
          <span className="sector-label sector-label-leading">Leading:</span>
          <span className="sector-list">
            {topIndustriesIncreases
              ?.slice(0, 3)
              .map(item => `${item.industry} (${Math.round(item.percentage)}%)`)
              .join(', ')}
          </span>
        </div>
        <div className="sector-group">
          <span className="sector-label sector-label-lagging">Lagging:</span>
          <span className="sector-list">
            {topIndustriesDecreases
              ?.slice(0, 3)
              .map(item => `${item.industry} (${Math.round(item.percentage)}%)`)
              .join(', ')}
          </span>
        </div>
      </div>
    </div>
  );
};

const MarketAnalysisRow = ({ 
  analysis, 
  guidance, 
  relatedEquities, 
  onAddSymbol, 
  userSettings = {}, 
  onSettingsChange
}) => {
  const [marketPulseData, setMarketPulseData] = useState(null);
  const [marketPulseError, setMarketPulseError] = useState(null);
  const [isLoadingMarketPulse, setIsLoadingMarketPulse] = useState(true);
  const [strategiesExpanded, setStrategiesExpanded] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [categoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isMainSectionExpanded, setIsMainSectionExpanded] = useState(false);

  // Separate data loading states
  const [marketPulseState, setMarketPulseState] = useState({
    data: null,
    isLoading: true,
    error: null
  });

  const [marketAnalysisState, setMarketAnalysisState] = useState({
    data: null,
    isLoading: true,
    error: null
  });

  // Fetch market pulse data
  useEffect(() => {
    const fetchMarketPulse = async () => {
      try {
        const response = await fetch(CONFIG.API_URLS.DAILY_MOVEMENT_ANALYTICS);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setMarketPulseState({
          data,
          isLoading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching market pulse:', error);
        setMarketPulseState({
          data: null,
          isLoading: false,
          error
        });
      }
    };

    fetchMarketPulse();
  }, []);

  // Fetch market analysis data
  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const data = await fetchMarketAnalysis();
        console.log('Fetched market analysis data:', data);
        setMarketAnalysisState({
          data,
          isLoading: false,
          error: null
        });
      } catch (error) {
        console.error('Error fetching market analysis:', error);
        setMarketAnalysisState({
          data: null,
          isLoading: false,
          error
        });
      }
    };

    fetchAnalysis();
  }, []);

  const getSentimentBadge = (sentiment) => {
    const colors = {
      positive: 'bg-green-100 text-green-800 font-medium',
      negative: 'bg-red-100 text-red-800 font-medium',
      neutral: 'bg-yellow-50 text-yellow-800 font-medium',
    };
    return `text-xs px-2 py-0.5 rounded-full ${colors[sentiment.toLowerCase()] || colors.neutral}`;
  };

  const getRiskLevelBadge = (riskLevel) => {
    const colors = {
      low: 'bg-green-100 text-green-800',
      medium: 'bg-yellow-100 text-yellow-800',
      high: 'bg-red-100 text-red-800',
    };
    return colors[riskLevel] || colors.medium;
  };

  const getTimeHorizonIcon = (horizon) => {
    switch (horizon) {
      case 'short-term':
        return <span className="text-blue-600">ST</span>;
      case 'medium-term':
        return <span className="text-purple-600">MT</span>;
      case 'long-term':
        return <span className="text-indigo-600">LT</span>;
      default:
        return null;
    }
  };

  const getSentimentClass = (sentiment) => {
    return `sentiment-badge sentiment-${sentiment.toLowerCase()}`;
  };

  // Add debug logging
  console.log('MarketAnalysisRow userSettings:', userSettings);
  console.log('MarketAnalysisRow onSettingsChange:', !!onSettingsChange);

  // Ensure analysis is being destructured correctly
  const { InvestmentStrategies } = analysis || {};

  return (
    <div className="market-analysis-container">
      <div className="bg-white shadow rounded-lg">
        {/* Header section always shows */}
        <div className="flex items-center justify-between px-4 py-3 cursor-pointer border-b border-gray-200" 
             onClick={() => setIsMainSectionExpanded(!isMainSectionExpanded)}>
          <div className="flex items-center gap-4">
            <h2 className="text-xl font-semibold text-gray-900">Market Analysis</h2>
            {marketPulseState.data?.data && (
              <div className="market-header-indicators">
                <span className="market-header-indicator market-header-advancing">
                  {Math.round((marketPulseState.data.data.numPriceIncreases / 
                    (marketPulseState.data.data.numPriceIncreases + marketPulseState.data.data.numPriceDecreases)) * 100)}% advancing
                </span>
                <span className="market-header-indicator market-header-declining">
                  {Math.round((marketPulseState.data.data.numPriceDecreases / 
                    (marketPulseState.data.data.numPriceIncreases + marketPulseState.data.data.numPriceDecreases)) * 100)}% declining
                </span>
              </div>
            )}
          </div>
          
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <FontAwesomeIcon
                icon={faMagicWandSparkles}
                className="w-4 h-4 text-blue-600 mr-1"
              />
              AI Assisted
            </div>
            {isMainSectionExpanded ? (
              <FiChevronUp className="w-4 h-4 text-gray-600" />
            ) : (
              <FiChevronDown className="w-4 h-4 text-gray-600" />
            )}
          </div>
        </div>

        {isMainSectionExpanded && (
          <div className="border-t border-gray-200">
            {/* Market Pulse Section */}
            <div className="px-4 py-3">
              <table className="w-full text-sm">
                <tbody>
                  <tr className="align-top bg-gray-50/50">
                    <td className="py-3 pr-6 w-40">
                      <span className="font-semibold text-blue-900">Market Pulse:</span>
                    </td>
                    <td className="py-3">
                      <MarketPulseSection {...marketPulseState} />
                    </td>
                  </tr>
                  
                  {/* Market Analysis Section */}
                  <tr className="align-top">
                    <td className="py-2.5 pr-6 w-40">
                      <span className="font-semibold text-blue-900">Market Analysis:</span>
                    </td>
                    <td className="py-2.5">
                      <MarketAnalysisSection {...marketAnalysisState} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Investment Strategies Section */}
            <button
              onClick={() => setStrategiesExpanded(!strategiesExpanded)}
              className="w-full flex items-center justify-between py-2 px-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <span className="text-sm font-medium text-gray-800">
                {strategiesExpanded ? 'Hide Investment Strategies' : 'Show Investment Strategies'}
              </span>
              {strategiesExpanded ? (
                <FiChevronUp className="w-4 h-4 text-gray-800" />
              ) : (
                <FiChevronDown className="w-4 h-4 text-gray-800" />
              )}
            </button>

            {strategiesExpanded && (
              <div className="px-4 py-3">
                <div className="grid gap-4 md:grid-cols-2">
                  {InvestmentStrategies?.map((strategy, index) => (
                    <div key={index} className="border rounded-lg p-4 bg-gray-50">
                      <div className="flex flex-wrap items-center gap-4 md:gap-6">
                        {/* Strategy Description */}
                        <div className="flex items-center gap-2 min-w-[250px] flex-1">
                          <FiTrendingUp className="w-4 h-4 text-gray-500" />
                          <span className="text-gray-900">{strategy.strategy}</span>
                        </div>
                        {/* Risk Level */}
                        <div className="flex items-center">
                          <span className={`
                            px-2.5 py-1 text-sm rounded-md
                            ${getRiskLevelBadge(strategy.riskLevel.toLowerCase())}
                          `}>
                            {strategy.riskLevel}
                          </span>
                        </div>
                        {/* Return Expectation */}
                        <div className="flex items-center gap-2 min-w-[100px]">
                          <FiTrendingUp className="w-4 h-4 text-gray-400" />
                          <div>
                            <div className="text-xs text-gray-500">Return</div>
                            <div className="font-medium">{strategy.expectedReturn}</div>
                          </div>
                        </div>
                        {/* Time Horizon */}
                        <div className="flex items-center gap-2 min-w-[100px]">
                          <FiClock className="w-4 h-4 text-gray-400" />
                          <div>
                            <div className="text-xs text-gray-500">Horizon</div>
                            <div className="font-medium">
                              {strategy.timeHorizon.replace('-', ' ')}
                            </div>
                          </div>
                        </div>
                        {/* Asset Classes */}
                        <div className="flex flex-wrap gap-2">
                          {strategy.assetClasses.map((assetClass, idx) => (
                            <span 
                              key={idx}
                              className="px-2.5 py-1 text-sm bg-gray-100 text-gray-700 rounded-md"
                            >
                              {assetClass}
                            </span>
                          ))}
                        </div>
                      </div>
                      {/* Risk-Return Profile */}
                      {strategy.expectedRiskReturnProfile && (
                        <div className="mt-3 flex items-start gap-2">
                          <FiAlertTriangle className="w-4 h-4 text-gray-400 mt-0.5" />
                          <p className="text-sm text-gray-600">
                            {strategy.expectedRiskReturnProfile}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Toggle Button for Categories Section */}
            <button
              onClick={() => setCategoriesExpanded(!categoriesExpanded)}
              className="w-full flex items-center justify-between py-2 px-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <span className="text-sm font-medium text-gray-800">
                {categoriesExpanded ? 'Hide Detailed Analysis' : 'Show Detailed Analysis'}
              </span>
              {categoriesExpanded ? (
                <FiChevronUp className="w-4 h-4 text-gray-800" />
              ) : (
                <FiChevronDown className="w-4 h-4 text-gray-800" />
              )}
            </button>

            {/* Categorized Analysis */}
            {categoriesExpanded && (
              <div className="px-4 py-3">
                <div className="space-y-2">
                  {Object.entries(marketAnalysisState.data?.analysis?.CategorizedAnalysis || {}).map(
                    ([category, data]) => (
                      <div key={category} className="border rounded-md overflow-hidden">
                        <button
                          onClick={() =>
                            setExpandedCategory(
                              expandedCategory === category ? null : category
                            )
                          }
                          className="w-full flex items-center justify-between py-2 px-3 bg-gray-50 hover:bg-gray-100 focus:outline-none"
                        >
                          <span className="text-sm font-medium text-gray-800">
                            {category.replace(/_/g, ' ')}
                          </span>
                          <div className="flex items-center gap-2">
                            <span className={getSentimentClass(data.sentiment)}>
                              {data.sentiment}
                            </span>
                            {expandedCategory === category ? (
                              <FiChevronDown className="w-4 h-4 text-gray-800" />
                            ) : (
                              <FiChevronRight className="w-4 h-4 text-gray-800" />
                            )}
                          </div>
                        </button>
                        {expandedCategory === category && (
                          <div className="px-3 py-2 text-sm text-gray-700">
                            <p className="mb-2">{data.analysis}</p>
                            {/* Add Key Trends and Impact Factors */}
                            {data.keyTrends && (
                              <div className="mt-2">
                                <h5 className="font-medium mb-1">Key Trends:</h5>
                                <ul className="list-disc list-inside">
                                  {data.keyTrends.map((trend, idx) => (
                                    <li key={idx}>{trend}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {data.impactFactors && (
                              <div className="mt-2">
                                <h5 className="font-medium mb-1">Impact Factors:</h5>
                                <ul className="list-disc list-inside">
                                  {data.impactFactors.map((factor, idx) => (
                                    <li key={idx}>{factor}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <NewsRow 
          onAddSymbol={onAddSymbol}
          userSettings={userSettings}
          onSettingsChange={onSettingsChange}
        />
      </div>
    </div>
  );
};

export default MarketAnalysisRow;
