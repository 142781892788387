import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import { FiChevronDown, FiChevronUp, FiPause, FiPlay, FiClock, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const NewsRow = ({ onAddSymbol, userSettings = {}, onSettingsChange }) => {
  console.log('NewsRow received settings:', userSettings);
  
  const { newsCarousel = {} } = userSettings;
  
  const [newsArticles, setNewsArticles] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(newsCarousel.isPaused || false);
  const [speed, setSpeed] = useState(newsCarousel.speed || 8000);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const tickerRefs = useRef(new Map());
  const [activeTooltipTicker, setActiveTooltipTicker] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://news.drisw.workers.dev/');
        const data = await response.json();
        setNewsArticles(data.articles || []);
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  // Modified carousel rotation effect
  useEffect(() => {
    if (!isExpanded && newsArticles.length > 0 && !isPaused) {
      const timer = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % newsArticles.length);
      }, speed);
      return () => clearInterval(timer);
    }
  }, [isExpanded, newsArticles.length, isPaused, speed]);

  const formatPublishedDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy, h:mm a');
    } catch (e) {
      return dateString;
    }
  };

  const handleSpeedChange = useCallback((newSpeed) => {
    setSpeed(newSpeed);
    onSettingsChange?.('newsCarousel', {
      ...newsCarousel,
      speed: newSpeed
    });
  }, [onSettingsChange, newsCarousel]);

  const togglePlayPause = useCallback(() => {
    const newPausedState = !isPaused;
    setIsPaused(newPausedState);
    onSettingsChange?.('newsCarousel', {
      ...newsCarousel,
      isPaused: newPausedState
    });
  }, [isPaused, onSettingsChange, newsCarousel]);

  const goToNextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % newsArticles.length);
  }, [newsArticles.length]);

  const goToPrevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + newsArticles.length) % newsArticles.length);
  }, [newsArticles.length]);

  const handleTickerClick = (e, ticker) => {
    e.preventDefault(); // Prevent the news article link from activating
    e.stopPropagation(); // Prevent event bubbling
    
    // Clean the ticker by removing $ or # symbols
    const cleanTicker = ticker.replace(/[$#]/g, '');
    onAddSymbol(cleanTicker);
  };

  const updateTooltipPosition = (ticker) => {
    const ref = tickerRefs.current.get(ticker);
    if (ref) {
      const rect = ref.getBoundingClientRect();
      setTooltipStyle({
        left: `${rect.left + (rect.width / 2)}px`,
        top: `${rect.top - 32}px`,
        opacity: 1,
        visibility: 'visible',
        transform: 'translateX(-50%)',
      });
      setActiveTooltipTicker(ticker);
    }
  };

  const handleMouseLeave = () => {
    setTooltipStyle(prev => ({
      ...prev,
      opacity: 0,
      visibility: 'hidden'
    }));
    setActiveTooltipTicker(null);
  };

  // Update states when userSettings change
  useEffect(() => {
    if (userSettings.newsCarousel) {
      if (userSettings.newsCarousel.speed !== undefined) {
        setSpeed(userSettings.newsCarousel.speed);
      }
      if (userSettings.newsCarousel.isPaused !== undefined) {
        setIsPaused(userSettings.newsCarousel.isPaused);
      }
    }
  }, [userSettings]);

  if (loading) {
    return <div className="animate-pulse h-40 bg-gray-100 rounded-lg"></div>;
  }

  return (
    <div className="news-section">
      <div className="flex items-center justify-between mb-4">
      <h2 className="text-xl font-semibold text-gray-900">News</h2>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
        >
          {isExpanded ? (
            <>Show Less <FiChevronUp /></>
          ) : (
            <>Show More <FiChevronDown /></>
          )}
        </button>
      </div>

      {isExpanded ? (
        <div className="news-grid">
          {newsArticles.map((article, idx) => (
            <a
              key={idx}
              href={article.url}
              target="_blank"
              rel="noopener noreferrer"
              className="news-card"
            >
              <div className="news-card-image">
                <img src={article.imageUrl} alt={article.title} />
              </div>
              <div className="p-3">
                <div className="news-meta">
                  <span className="news-source">{article.source}</span>
                  <span className="news-date">
                    {formatPublishedDate(article.publishedAt)}
                  </span>
                </div>
                <h3 className="news-title">{article.title}</h3>
                <p className="news-description">{article.description}</p>
                {article.tickers && article.tickers.length > 0 && (
                  <div className="news-tickers">
                    {article.tickers.map((ticker, idx) => (
                      <span
                        key={idx}
                        className="news-ticker hover:bg-blue-200 cursor-pointer"
                        onClick={(e) => handleTickerClick(e, ticker)}
                      >
                        {ticker}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </a>
          ))}
        </div>
      ) : (
        <div className="news-carousel">
          {newsArticles.length > 0 && (
            <>
              <div className="news-carousel-content">
                <a 
                  href={newsArticles[currentSlide].url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="news-thumbnail hover:opacity-80 transition-opacity"
                >
                  <img
                    src={newsArticles[currentSlide].imageUrl}
                    alt={newsArticles[currentSlide].title}
                  />
                </a>
                <div className="flex-grow">
                  <div className="news-meta">
                    <span className="news-source">
                      {newsArticles[currentSlide].source}
                    </span>
                    <span className="news-date">
                      {formatPublishedDate(newsArticles[currentSlide].publishedAt)}
                    </span>
                  </div>
                  <a 
                    href={newsArticles[currentSlide].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block hover:text-blue-600 transition-colors"
                  >
                    <h3 className="news-title">{newsArticles[currentSlide].title}</h3>
                  </a>
                  <p className="news-description">
                    {newsArticles[currentSlide].description}
                  </p>
                  {newsArticles[currentSlide].tickers && (
                    <div className="news-tickers">
                      {newsArticles[currentSlide].tickers.map((ticker, idx) => (
                        <span
                          key={idx}
                          className="ticker-tooltip-container"
                        >
                          <span
                            ref={(el) => tickerRefs.current.set(ticker, el)}
                            className="news-ticker hover:bg-blue-200 cursor-pointer"
                            onClick={(e) => handleTickerClick(e, ticker)}
                            onMouseEnter={() => updateTooltipPosition(ticker)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {ticker}
                          </span>
                          {activeTooltipTicker === ticker && (
                            <span 
                              className="earnings-estimate-tooltip" 
                              style={tooltipStyle}
                            >
                              Add {ticker} to watchlist
                            </span>
                          )}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="carousel-controls">
                <div className="carousel-controls-left">
                  <button
                    onClick={togglePlayPause}
                    className="control-button"
                    title={isPaused ? "Play" : "Pause"}
                  >
                    {isPaused ? <FiPlay /> : <FiPause />}
                  </button>
                  
                  <div className="navigation-controls">
                    <button
                      onClick={goToPrevSlide}
                      className="control-button"
                      title="Previous"
                    >
                      <FiChevronLeft />
                    </button>
                    <button
                      onClick={goToNextSlide}
                      className="control-button"
                      title="Next"
                    >
                      <FiChevronRight />
                    </button>
                  </div>
                  
                  <div className="speed-controls">
                    <FiClock className="speed-icon" />
                    <select
                      value={speed}
                      onChange={(e) => handleSpeedChange(Number(e.target.value))}
                      className="speed-select"
                    >
                      <option value={4000}>4s</option>
                      <option value={8000}>8s</option>
                      <option value={12000}>12s</option>
                      <option value={16000}>16s</option>
                    </select>
                  </div>
                </div>

                <div className="carousel-dots">
                  {newsArticles.map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => setCurrentSlide(idx)}
                      className={`carousel-dot ${currentSlide === idx ? 'active' : ''}`}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsRow; 