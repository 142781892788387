import React, { useRef, useState, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Favicon from "./Favicon";
import AiAnalysisPanel from "./AiAnalysisPanel";
import "./CompanyRow.css";
import {
  formatDate,
  daysUntilEarnings,
  getChangeClass,
  formatChange,
  getRowStyle,
  formatNumberAbbreviated, // Import it from utils.js
  formatMarketCap, // Import if you're using it
} from "./utils";
import EarningsBadge from "./EarningsBadge";
import TechnicalSignalIcons from './TechnicalSignalIcons';

const TrafficCell = ({ trafficData, trafficChange }) => {
  if (!trafficData || trafficData.length === 0) {
    return <div>N/A</div>;
  }

  const currentTraffic = trafficData[0].visits;
  const formattedTraffic = formatNumberAbbreviated(currentTraffic);

  return (
    <div>
      {formattedTraffic}{" "}
      <span className={getChangeClass(trafficChange)}>
        {formatChange(trafficChange)}
      </span>
    </div>
  );
};



const CompanyRow = ({
  company,
  expandedRow,
  toggleRow,
  onAddToWatchList,
  onDelete,
  dateColumnStyle,
  selectedTab,
  colorSettings,
  columns, // Accept columns as a prop
}) => {
  const rowStyle = getRowStyle(company, colorSettings);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = useCallback(
    (e) => {
      e.stopPropagation();
      setIsDropdownOpen((prev) => !prev);
    },
    []
  );

  const handleAddToWatchList = useCallback(
    (e) => {
      e.stopPropagation();
      onAddToWatchList(company);
      setIsDropdownOpen(false);
    },
    [company, onAddToWatchList]
  );

  const handleDelete = useCallback(
    async (e) => {
      e.stopPropagation();
      try {
        await onDelete(company.tickerSymbol);
      } catch (error) {
        console.error("Error deleting company:", error);
      }
      setIsDropdownOpen(false);
    },
    [company.tickerSymbol, onDelete]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const renderLogo = () => {
    if (company.logo_url) {
      return (
        <img
          src={company.logo_url}
          alt={`${company.name} logo`}
          className="w-6 h-6 mr-2"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
              company.name
            )}&background=random`;
          }}
        />
      );
    } else {
      return (
        <div className="w-6 h-6 mr-2 bg-gray-200 flex items-center justify-center rounded-full">
          {company.name.charAt(0)}
        </div>
      );
    }
  };

  const iconStyle = {
    width: "16px",
    height: "16px",
    color: "#6B7280", // Tailwind's gray-500
  };

  // Function to render cell content based on column key
  const renderCellContent = (column) => {
    const { key, format } = column;

    // Add debug logging
    //console.log('Rendering cell for column:', key, 'Company data:', company);

    switch (key) {
      case "date":
        if (!company.earningsDate) {
          console.log('Missing earnings date for:', company.tickerSymbol);
        }
        return (
          <div className="text-sm">
            {formatDate(company.earningsDate)}
            <br />
            <EarningsBadge
              date={company.earningsDate}
              isEstimated={company.isEarningsDateEstimated}
            />
          </div>
        );

      case "name":
        if (!company.name || !company.tickerSymbol) {
          console.log('Missing name/ticker for company:', company);
        }
        return (
          <div className="flex items-center">
            {renderLogo()}
            <div>
              <strong>
                {company.name}{" "}
                <span className="text-gray-500">
                  ({company.tickerSymbol})
                </span>
              </strong>
              <br />
              {company.website && (
                <a
                  href={
                    company.website.startsWith("http")
                      ? company.website
                      : `https://${company.website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {company.website}
                </a>
              )}
            </div>
            <div className="ml-auto relative" ref={dropdownRef}>
              <button
                className={`text-gray-600 hover:text-gray-800 focus:outline-none p-1 rounded-full transition-colors duration-200 ease-in-out ${
                  isDropdownOpen ? "bg-gray-200" : "hover:bg-gray-100"
                }`}
                type="button"
                onClick={handleDropdownToggle}
                aria-label="Company options"
              >
                <FontAwesomeIcon icon={faEllipsisV} className="w-4 h-4" />
              </button>
              {isDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 py-2 bg-white rounded-md shadow-xl z-50"
                  style={{
                    width: "200px",
                    top: "100%",
                    right: "0",
                  }}
                >
                  {selectedTab !== "watchlist" && (
                    <button
                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      onClick={handleAddToWatchList}
                    >
                      Add to Watch List
                    </button>
                  )}
                  {selectedTab === "watchlist" && (
                    <button
                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        );

      case "stockChange":
        return (
          <>
            {`${parseFloat(company.stockPrice).toFixed(2)} `}
            <span className={getChangeClass(company.stockChange)}>
              {formatChange(company.stockChange)}
            </span>
          </>
        );

      case "stockPrice":
        return (
          <>
            {company.stockPrice != null ? `$${company.stockPrice.toFixed(2)}` : 'N/A'}{" "}
            {company.regularMarketChangePercent != null && (
              <span className={getChangeClass(company.regularMarketChangePercent)}>
                {formatChange(company.regularMarketChangePercent)}
              </span>
            )}
          </>
        );

      case "trafficChange":
        return (
          <TrafficCell
            trafficData={company.trafficData}
            trafficChange={company.trafficChange}
          />
        );

      case "positionsPercentage":
        return (
          <>
            {company.positions}
            {company.positionsPercentage !== null && (
              <span className={getChangeClass(company.positionsPercentage)}>
                {` ${formatChange(company.positionsPercentage)}`}
              </span>
            )}
          </>
        );

      case "outlook":
        const outlookValue = company.outlook;
        if (outlookValue == null || isNaN(outlookValue)) {
          return "N/A";
        }
        return parseFloat(outlookValue).toFixed(2); // Display value between 0 and 1

      case "dividendYield":
        if (company.dividendYield == null || isNaN(company.dividendYield)) {
          return "N/A";
        }
        return `${company.dividendYield.toFixed(2)}%`; // No multiplication

      case "marketCap":
        if (company.marketCap == null || isNaN(company.marketCap)) {
          return "N/A";
        }
        return formatMarketCap(company.marketCap);

      case "actions":
        // Render the expand/collapse icon
        const isExpanded = expandedRow === company.tickerSymbol;
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              toggleRow(company.tickerSymbol);
            }}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Toggle Row Expansion"
          >
            {isExpanded ? (
              <FiChevronUp style={{ width: "16px", height: "16px" }} />
            ) : (
              <FiChevronDown style={{ width: "16px", height: "16px" }} />
            )}
          </button>
        );

      case "growth":
        if (company.revenueGrowth == null || isNaN(company.revenueGrowth)) {
          return "N/A";
        }
        return `${(company.revenueGrowth * 100).toFixed(2)}%`;

      case "shortFloat":
        if (company.shortPercentOfFloat == null || isNaN(company.shortPercentOfFloat)) {
          return "N/A";
        }
        return `${(company.shortPercentOfFloat * 100).toFixed(2)}%`;

      case "margins":
        if (company.grossMargins == null || company.operatingMargins == null) {
          return "N/A";
        }
        return (
          <>
            {(company.grossMargins * 100).toFixed(1)}%{" "}
            <span className={getChangeClass(company.operatingMargins)}>
              {formatChange(company.operatingMargins * 100)}
            </span>
          </>
        );

      case "beta":
        if (company.beta == null || isNaN(company.beta)) {
          return "N/A";
        }
        return company.beta.toFixed(2);

      case "pegRatio":
        if (company.pegRatio == null || isNaN(company.pegRatio)) {
          return "N/A";
        }
        return company.pegRatio.toFixed(2);

      case "technicalSignals":
        return <TechnicalSignalIcons signals={company.technicalSignals} />;

      default:
        // For any other columns, display the value directly
        let value = company[key];
        if (format === "date" && value) {
          value = formatDate(value);
        } else if (format === "percentage" && value != null) {
          value = `${(parseFloat(value) * 100).toFixed(2)}%`;
        }
        return value != null ? value : "N/A";
    }
  };

  return (
    <>
      <tr
        className="hover:bg-gray-50 cursor-pointer"
        style={rowStyle}
        onClick={() => toggleRow(company.tickerSymbol)}
      >
        {columns.map((column) => (
          <td
            key={column.key}
            className="py-2 px-4 border-b"
            style={column.key === 'date' ? dateColumnStyle : {}}
          >
            {renderCellContent(column)}
          </td>
        ))}
      </tr>
      {expandedRow === company.tickerSymbol && (
        <tr>
          <td colSpan={columns.length} className="p-4 bg-gray-100">
            <AiAnalysisPanel
              aiAnalysis={company.ai_analysis}
              stock={company}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default CompanyRow;
